<template>
  <div class="course-list" v-wechat-title="'课程培训'">
    <div class="course-list-search" @click="courseSearch">
      <van-search
        v-model="searchValue"
        readonly
        shape="round"
        placeholder="搜索关键词"
        :left-icon="require('@/assets/img/ss.png')"
      />
    </div>
    <div class="course-list-tag">
      <span :class="{'active':courseTypeId == ''}" @click="courseType('')">全部</span>
      <span v-for="item in dict.COURSE_TYPE" :key="item.id" :class="{'active':courseTypeId == item.itemValue}"
            @click="courseType(item.itemValue)">{{ item.itemText }}</span>
    </div>
    <div>
      <van-list
        v-if="courseList.length>0"
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
      >
        <div class="course-list-box">
          <div v-for="(item, index) in courseList" :key="index" @click="courseInfo(item.id,item.vodStatus,item.crsCourseActivityIds)">
            <p>
              <span v-if="item.isOnTop"><img src="@/assets/img/tjtb.png" alt="tjtb"/></span>
              <v-img :attachment-id="item.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')"/>
              <img src="@/assets/img/crs_lock.png" v-if="item.vodStatus && item.videoType==1" id="crs-lock"/>
            </p>
            <div>
              <span>{{ item.courseName }}</span>
              <p>
                <span>共{{ item.chapterTotal }}节课</span>
                <span>{{ item.viewed ? item.viewed : 0 }}人浏览</span>
              </p>
            </div>
          </div>
        </div>
      </van-list>


      <van-empty
        v-if="emptybool"
        class="custom-image"
        :image="nonecontent"
        description="暂无内容"
      />

    </div>
    <share :val="shareInfo"/>
  </div>
</template>

<script>
import { getCourseList,verificationEnroll } from '@/api/activity'
import VImg from '@/components/VImg'
import { mapGetters, mapActions } from 'vuex'
import nonecon from '@/assets/img/nonecontent1.png'
import share from '../components/share.vue'
import storage from 'store'

export default {
  components: {
    VImg,
    share
  },
  data () {
    return {
      nonecontent: nonecon,
      emptybool: false,
      searchValue: '',
      loading: false,
      finished: false,
      page: {
        current: 1,
        size: 10,
        pageSize: 1,
      },
      courseList: [],
      courseTypeId: '',
      shareInfo: {
        title: '课程培训',
        desc: '定制、专业、实战化的教培活动体系',
        img: require('@/assets/img/logoinimg.png')
      }
    }
  },
  computed: mapGetters(['dict','userInfo']),
  mounted () {
    if (this.$route.query.type) {
      this.courseTypeId = this.$route.query.type
    }
    this.LoadDictData(['COURSE_TYPE'])
    this.loadGetCourseList()
    if (this.$route.query.code) {
      this.wechatUtil.saveWechatInfo(this.$route.query.code, localStorage.getItem('openId'))
    }
  },
  methods: {
    ...mapActions(['LoadDictData']),

    onLoad () {
      if (this.courseList == '') {
        return
      }
      if (this.page.pageSize == this.page.current) {
        this.finished = true
        return
      }
      this.page.current++
      this.loading = true
      this.loadGetCourseList()
    },
    // 列表
    loadGetCourseList () {
      let {
        page,
        courseTypeId
      } = this
      let data = {
        courseName: '',
        orderField: '',
        ascOrDesc: 'desc',
        courseTypeId
      }
      getCourseList(page, data).then((res) => {
        this.loading = false
        this.courseList = [...this.courseList, ...res.records]
        this.page.pageSize = res.pages
        this.finished = false

        if (this.courseList.length == 0) {
          this.emptybool = true
        } else {
          this.emptybool = false
        }

      })
    },
    courseType (id) {
      this.page.current = 1
      this.courseList = []
      this.courseTypeId = id
      this.finished = false
      this.loadGetCourseList()
    },
    courseSearch () {
      this.$router.push('/course/search')
    },
    courseInfo (id, vodStatus, activityIds) {
      console.log(this.userInfo);
      if (vodStatus) {
        //有配置点播权限
        if (!this.userInfo) {
          this.$toast('您还未登录！')
          this.$router.push('/login');
          return;
        }

        //判断点播权限
        if (activityIds && this.userInfo.phone) {
          verificationEnroll(activityIds).then((res) => {
            if (!res) {
              this.$toast('很抱歉，该课程您无权查看~')
              return;
            } else {
              this.$router.push({
                path: "/course/info",
                query: {id},
              });
            }
          });
        }
      } else {
        this.$router.push({
          path: "/course/info",
          query: {id},
        });
      }

    }
  },
}
</script>

<style lang="scss" scoped>
.custom-image {
  ::v-deep {
    .van-empty__image {
      width: 114px;
      height: 74.09px;
    }

    .van-empty__description {
      font-size: 14px;
      font-weight: 500;
      line-height: 46px;
      color: #999999;
    }
  }
}

.course-list {
  min-height: 100%;
  background: #f6f6f6;

  .course-list-search {
    padding: 8px 16px 0;
  }

  .course-list-tag {
    border-bottom: 1px solid #eee;
    display: flex;
    justify-content: center;

    > span {
      height: 50px;
      line-height: 50px;
      margin-right: 30px;
      font-size: 16px;
      color: #000;
      border-bottom: 3px solid transparent;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        color: #EA0B06;
        border-color: #EA0B06;
      }
    }
  }

  .course-list-box {
    padding: 0 16px;

    > div {
      margin-top: 10px;
      background: #fff;
      padding: 12px;
      border-radius: 4px;
      display: flex;

      > p {
        width: 140px;
        height: 78px;
        margin-right: 12px;
        position: relative;
        margin-bottom: 0;

        > img {
          width: 100%;
          height: 100%;
          display: block;
          border-radius: 4px;
        }

        > span {
          position: absolute;
          left: 4px;
          top: -4px;
          width: 32px;

          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
      }

      > div {
        flex: 1;

        > span {
          display: block;
          font-size: 14px;
          line-height: 20px;
          color: #333333;
          word-break: break-all;
          height: 40px;
          overflow: hidden;
        }

        > p {
          display: flex;
          justify-content: space-between;
          margin-bottom: 0;
          margin-top: 21px;

          > span {
            font-size: 12px;
            line-height: 17px;
            color: #999999;
          }
        }
      }
    }
  }
}
</style>
<style>
.course-list-search .van-search {
  background: none;
  padding: 0;
}

.course-list-search .van-search__content {
  background: #fff;
  color: #c4c4c4;
}

.course-list-search .van-field__left-icon {
  display: flex;
  align-items: center;
}
#crs-lock {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5%;
  top: 75%;
}
</style>
